import { merge } from "theme-ui"
import tailwind from '@theme-ui/preset-tailwind'
import { cyberpunk } from "../styles/color"

const baseFonts = {
  brand: 'moby,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  sans: 'moby,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  serif: '"Times New Roman",Times,serif',
  mono: 't26-carbon, monospace',
}

const fonts = {
  ...baseFonts,
  body: baseFonts.mono,
  heading: baseFonts.sans,
  monospace: baseFonts.mono,
}

const theme = merge(tailwind, {
  config: {
    initialColorModeName: `dark`,
    useColorSchemeMediaQuery: `system`,
  },
  colors: {
    primary: cyberpunk.orchid,
    secondary: tailwind.colors.indigo[6],
    text: cyberpunk.lavender,
    heading: tailwind.colors.white,
    background: tailwind.colors.black,
    divider: cyberpunk.lavender,
    textMuted: cyberpunk.lavender,
    icon_brightest: tailwind.colors.white,
    icon_darker: tailwind.colors.gray[7],
    icon_darkest: cyberpunk.almostBlack,
    icon_red: tailwind.colors.red[6],
    icon_blue: tailwind.colors.blue[6],
    icon_orange: tailwind.colors.orange[5],
    icon_yellow: tailwind.colors.yellow[5],
    icon_pink: tailwind.colors.pink[5],
    icon_purple: tailwind.colors.purple[6],
    icon_green: tailwind.colors.green[5],
    modes: {
      light: {
        heading: cyberpunk.almostBlack,
        background: cyberpunk.blueBeard,
        divider: tailwind.colors.gray[2],
        icon_brightest: tailwind.colors.gray[2],
        icon_darker: tailwind.colors.gray[4],
        icon_darkest: tailwind.colors.gray[6],
      },
    },
  },
  breakpoints: [`400px`, `600px`, `900px`, `1200px`, `1600px`],
  fonts,
  footer: {
    textAlign: `center`,
    display: `block`,
    position: `absolute`,
    bottom: 0,
    color: `textMuted`,
    px: [2, 3],
    py: [3, 4],
  },
  styles: {
    root: {
      margin: 0,
      padding: 0,
      boxSizing: `border-box`,
      textRendering: `optimizeLegibility`,
      WebkitFontSmoothing: `antialiased`,
      MozOsxFontSmoothing: `grayscale`,
      color: `text`,
      backgroundColor: `background`,
      a: {
        color: `primary`,
        textDecoration: `none`,
        transition: `all 0.3s ease-in-out`,
        "&:hover": {
          color: `primary`,
          textDecoration: `none`,
        },
      },
    },
    navlink: {
      color: [`text`, `text`, tailwind.colors.white],
      textDecoration: `none`,
      [`&:hover`]: {
        color: cyberpunk.orchid
      }
    },
    p: {
      fontSize: [
        tailwind.fontSizes.default,
        tailwind.fontSizes.default,
        tailwind.fontSizes.lg,
      ],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`,
    },
    blockquote: {
      marginLeft: 0,
      p: {
        fontSize: [tailwind.fontSizes["lg"], tailwind.fontSizes["2xl"]],
        fontWeight: `medium`,
        color: `heading`,
      },
    },
    h1: {
      fontSize: [
        tailwind.fontSizes["3xl"],
        tailwind.fontSizes["4xl"],
        tailwind.fontSizes["5xl"],
        tailwind.fontSizes["6xl"],
      ],
      mt: 2,
      mb: 3,
      textShadow: `${cyberpunk.orchid} 0px 0 35px`,
      color: `heading`,
    },
    h2: {
      fontSize: [
        tailwind.fontSizes["2xl"],
        tailwind.fontSizes["3xl"],
        tailwind.fontSizes["4xl"],
        tailwind.fontSizes["5xl"],
      ],
      mt: 2,
      mb: 2,
      color: `heading`,
    },
    h3: {
      fontFamily: `monospace`,
      textTransform: `uppercase`,
      letterSpacing: `wide`,
      fontSize: [
          tailwind.fontSizes["lg"],
          tailwind.fontSizes["lg"],
          tailwind.fontSizes["2xl"],
          tailwind.fontSizes["2xl"]
      ],
      fontWeight: `medium`,
      lineHeight: 1,
      mt: 3,
      color: `heading`,
    },
    h4: {
      fontSize: [
        tailwind.fontSizes["default"],
        tailwind.fontSizes["lg"],
        tailwind.fontSizes["2xl"],
        tailwind.fontSizes["3xl"],
      ],
      color: `heading`,
    },
    h5: {
      fontSize: [
        tailwind.fontSizes["default"],
        tailwind.fontSizes["lg"],
        tailwind.fontSizes["2xl"],
        tailwind.fontSizes["3xl"],
      ],
      color: `heading`,
    },
    h6: {
      fontSize: 1,
      mb: 2,
      color: `heading`,
    },
    brand: {
      a: {
        color: tailwind.colors.white,
        fontSize: tailwind.fontSizes.default,
        textShadow: `${cyberpunk.orchid} 0 0 18px`,
        svg: {
          position: `relative`,
          bottom: `-5px`,
          marginRight: `10px`,
          filter: `drop-shadow(0 0 7px ${cyberpunk.orchid})`,
          g: {
            transition: `all 0.3s ease-in-out`,
          }
        },
        "&:hover": {
          color: cyberpunk.orchid,
          svg: {
            g: {
              stroke: cyberpunk.orchid,
            },
          }
        }
      },
    }
  },
  layout: {
    container: {
      maxWidth: `5xl`,
    },
  },
  buttons: {
    toggle: {
      color: `background`,
      border: `none`,
      backgroundColor: `text`,
      cursor: `pointer`,
      alignSelf: `center`,
      px: 3,
      py: 2,
      ml: 3,
    },
  },
  texts: {
    bigger: {
      p: {
        fontSize: [
          tailwind.fontSizes["default"],
          tailwind.fontSizes["default"],
          tailwind.fontSizes["lg"],
          tailwind.fontSizes["2xl"],
        ],
      },
    },
  },
})

export default theme
