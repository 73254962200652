export const cyberpunk = {
  skyBlue: `#6ecbf5`,
  skyBlueWhite: `#BBEAF6`,
  orchid: `#c252e1`,
  orchidWhite: `#E7A5F9`,
  lavender: `#e0d9f6`,
  royalBlue: `#586ae2`,
  midnightBlue: `#2a2356`,
  blueBeard: `#111632`,
  almostBlack: `#0B0C20`
}

export const textGlow = (textColor, shadowColor, shadowSpread) => ({
  color: textColor,
  textShadow: `0 0 ${shadowSpread} ${shadowColor}`
})
